.loading-mask {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(0, 0, 0, .1);
    display: flex;
    align-items: center;
    justify-content: center;

    .loading-icon {
        width: 205px;
        height: 145px;
        background: url(../../assets/loading.png) center no-repeat;
    }
}
