.toggle-bar {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.toggle-intro {
    font-size: 16px;
    color: #24272A;
    margin-right: 10px;
}
.toggle-group {
    display: flex;
}
.toggle-btn {
    width: 108px;
    height: 24px;
    font-size: 16px;
    color: #5D3F39;
    text-align: center;
    line-height: 24px;
    margin-right: 5px;
    cursor: pointer;
    background: url(../../assets/btn-toggle.png) center no-repeat;

    &:hover {
        background-image: url(../../assets/btn-toggle_hover.png);
    }
    &.actived {
        color: #fff;
        background-image: url(../../assets/btn-toggle_selected.png);
        cursor: default;
    }
}