.page-publish {
    background: #000 url(../../assets/publish_bg.jpg) center / 2560px 1440px no-repeat;
}
.page-publish-header {
    position: relative;
    z-index: 1;

    .page-title {
        position: absolute;
        left: 382px;
        top: 28px;
        width: 122px;
        height: 34px;
        background: url(../../assets/publish_title.png) center no-repeat;
    }

    .btn-back {
        position: absolute;
        left: 245px;
        top: 11px;
        cursor: pointer;
    }
}
.page-publish-content {
    flex: 1;
    overflow: hidden;
    display: flex;
    position: absolute;
    top: 190px;
    left: 550px;
    right: 550px;
    bottom: 25px;
}
