.comment-list-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
}
.comment-list-container {
    overflow: auto;
    flex: 1;
}
.comment-list-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.comment-list-body {
    margin-top: 20px;
}
.comment-list-footer {
    display: flex;
    padding: 20px 20px 0;
    justify-content: flex-end;
}
.comment-streamer {
    .streamer-platfrom {
        display: flex;
        align-items: center;
    }
    .streamer-platfrom-icon {
        width: 16px;
        height: 16px;
        margin-right: 5px;
    }
    .streamer-platfrom-name {
        color: #666;
        font-size: 14px;
    }
    .streamer-name {
        font-size: 16px;
        color: #333;
    }
}
.comment-toolbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.comment-card {
    position: relative;
    margin: 0 10px 33px 85px;

    .card-header {
        display: flex;
        flex-direction: column;
    }
    .card-title {
        color: #5D3F39;
        font-size: 24px;
    }
    .card-subtitle {
        flex: 1;
        display: flex;
        margin-top: 5px;
        align-items: center;
    }
    .card-name {
        color: #907D79;
        font-size: 16px;
        margin-right: 16px;
    }
    .card-time {
        color: #907D79;
        font-size: 14px;
    }
    .card-status {
        position: absolute;
        top: 0;
        right: 0;
        width: 144px;
        height: 48px;

        &.pending {
            background-image: url(../../assets/status_pending.png);
        }
        &.approved {
            background-image: url(../../assets/status_approved.png);
        }
        &.denied {
            background-image: url(../../assets/status_denied.png);
        }
        &.blocked {
            background-image: url(../../assets/status_blocked.png);
        }
    }
    .card-content {
        color: #5D3F39;
        font-size: 16px;
        line-height: 30px;
        white-space: pre-wrap;
        word-break: break-word;
        margin: 20px 0 10px;
    }
    .card-footer {
        display: flex;
        padding: 10px 20px;
        justify-content: flex-end;
        align-items: center;
    }
    .card-feedback-bar {
        display: flex;
    }
    .card-feedback-btn {
        color: #999;
        display: flex;
        width: 90px;
        height: 28px;
        margin-right: 5px;
        align-items: center;
        cursor: pointer;
        background: url(../../assets/btn-emoji.png) center no-repeat;

        &:hover {
            background-image: url(../../assets/btn-emoji_hover.png);

            .card-feedback-icon {
                &.icon-1 {
                    background-image: url(../../assets/icon-love_hover.png);
                }
                &.icon-2 {
                    background-image: url(../../assets/icon-smile_hover.png);
                }
                &.icon-3 {
                    background-image: url(../../assets/icon-praise_hover.png);
                }
                &.icon-4 {
                    background-image: url(../../assets/icon-hug_hover.png);
                }
            }
        }
        &.actived {
            background-image: url(../../assets/btn-emoji_selected.png);
        }
    }
    .card-feedback-icon {
        width: 28px;
        height: 28px;
        margin-right: 10px;

        &.icon-1 {
            background-image: url(../../assets/icon-love.png);
        }
        &.icon-2 {
            background-image: url(../../assets/icon-smile.png);
        }
        &.icon-3 {
            background-image: url(../../assets/icon-praise.png);
        }
        &.icon-4 {
            background-image: url(../../assets/icon-hug.png);
        }
    }
    .card-feedback-amount {
        color: #5D3F39;
        font-size: 14px;
        line-height: 24px;
    }
    .card-toolbar {
        display: flex;
        align-items: center;
    }
    .card-forward-info {
        font-size: 16px;
        color: #5D3F39;
    }
    .card-sep {
        width: 702px;
        height: 2px;
        background: url(../../assets/sep.png) center no-repeat;
    }
    .card-sign-new {
        position: absolute;
        top: 0;
        left: -57px;
        width: 54px;
        height: 42px;
        background: url(../../assets/sign_new.png) center no-repeat;
    }
    .card-sign-forward {
        position: absolute;
        top: -22px;
        right: 13px;
        width: 105px;
        height: 102px;
        background: url(../../assets/sign_forward.png) center no-repeat;
    }
    .blocked-reason-trigger {
        position: absolute;
        right: 12px;
        top: 56px;
        width: 92px;
        height: 14px;
        box-sizing: border-box;
        padding-left: 16px;
        line-height: 8px;
        color: #C84B41;
        font-size: 14px;
        cursor: default;
        background: url(../../assets/blocked-trigger.png) center no-repeat;

        &:hover {
            background-image: url(../../assets/blocked-trigger_hover.png);
            color: #2A9C70;

            & + .blocked-reason-message {
                display: block;
            }
        }
    }
    .blocked-reason-message {
        display: none;
        position: absolute;
        right: 12px;
        top: 80px;
        box-sizing: border-box;
        padding: 6px 8px 4px 8px;
        max-width: 226px;
        min-height: 32px;
        color: #fff;
        font-size: 14px;
        word-break: break-all;
        border-image-source: url(../../assets/blocked-reason_bg.png);
        border-image-slice: 12 14 16 19 fill;
        border-image-width: 12px 14px 16px 19px;
        border-image-repeat: stretch;
        border-image-outset: 6px 5px 9px 6px;;
        background: #a59785;
        z-index: 1;
    }
}
.comment-edit-content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 60vw;
    height: 60vh;
    box-sizing: border-box;

    .comment-title {
        border: 1px solid #aaa;
        padding: 10px;
        width: 100%;
        border-radius: 3px;
        font-size: 14px;
        margin-bottom: 10px;
        box-sizing: border-box;
    }

    .comment-text {
        border: 1px solid #ccc;
        border-radius: 5px;
        resize: none;
        flex: 1;
        width: 100%;
        padding: 10px;
        line-height: 1.5;
        font-size: 14px;
        box-sizing: border-box;
    }
}
.comment-foward-content {
    display: flex;
    flex: 1;
    overflow: hidden;
    background: #f1f3f5;
}
.comment-add-content {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
    margin-top: 40px;
}

.forward-modal {
    display: flex;
    flex-direction: column;
    width: 1446px;
    height: 1026px;
    background: url(../../assets/forward-modal-bg.png) center no-repeat;
}
.forward-modal-header {
    position: relative;
    z-index: 1;
    height: 140px;

    .page-title {
        position: absolute;
        left: 209px;
        top: 58px;
        width: 61px;
        height: 34px;
        background: url(../../assets/forward_title.png) center no-repeat;
    }

    .btn-close {
        position: absolute;
        right: 100px;
        top: 44px;
        cursor: pointer;
    }
}
.forward-modal-body {
    display: flex;
    flex: 1 1;
    overflow: hidden;
    margin: 0 108px 60px 195px;
}


.edit-modal {
    display: flex;
    flex-direction: column;
    width: 908px;
    height: 562px;
    background: url(../../assets/edit-modal_bg.png) center no-repeat;
}
.edit-modal-header {
    position: relative;
    z-index: 1;
    height: 140px;

    .page-title {
        position: absolute;
        left: 87px;
        top: 64px;
        width: 121px;
        height: 34px;
        background: url(../../assets/edit_title.png) center no-repeat;
    }

    .btn-close {
        position: absolute;
        right: 85px;
        top: 36px;
        cursor: pointer;
    }
}
.edit-modal-body {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;

    .content {
        height: 270px;
    }
}

.message-modal {
    display: flex;
    flex-direction: column;
    width: 978px;
    height: 300px;
    box-sizing: border-box;
    padding: 50px 50px 80px;
    background: url(../../assets/message-bg.png) center no-repeat;
}
.message-modal-content {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}
.message-modal-message {
    flex: 1;
    color: #5D3F39;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
}
.message-modal-toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
}