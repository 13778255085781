.app {
    height: 100vh;
    overflow: hidden;
    background-color: #f1f3f5;
}

.btn-exit {
    width: 90px;
    height: 90px;
    background: url('./assets/btn-exit.png') center center no-repeat;

    &:hover {
        background-image: url('./assets/btn-exit_hover.png');
    }
    &:active {
        background-image: url('./assets/btn-exit_actived.png');
    }
}

.btn-back {
    width: 90px;
    height: 90px;
    background: url('./assets/btn-back.png') center center no-repeat;

    &:hover {
        background-image: url('./assets/btn-back_hover.png');
    }
    &:active {
        background-image: url('./assets/btn-back_actived.png');
    }
}

.btn-close {
    width: 70px;
    height: 70px;
    background: url('./assets/btn-close.png') center center no-repeat;

    &:hover {
        background-image: url('./assets/btn-close_hover.png');
    }
    &:active {
        background-image: url('./assets/btn-close_actived.png');
    }
}

.btn-primary {
    width: 150px;
    height: 40px;
    font-size: 20px;
    text-align: center;
    text-decoration: none;
    line-height: 28px;
    color: #ededed;
    cursor: pointer;
    background: url('./assets/btn-primary.png') center center no-repeat;

    &:hover {
        background-image: url('./assets/btn-primary_hover.png');
    }
    &:active {
        background-image: url('./assets/btn-primary_actived.png');
    }
    &.disabled {
        pointer-events: none;
        opacity: .6;
    }
}

.btn-forward {
    width: 140px;
    height: 56px;
    font-size: 20px;
    text-align: center;
    text-decoration: none;
    line-height: 56px;
    color: #ededed;
    cursor: pointer;
    background: url('./assets/btn-forward.png') center center no-repeat;

    &:hover {
        background-image: url('./assets/btn-forward_hover.png');
    }
    &:active {
        color: #36AF81;;
        background-image: url('./assets/btn-forward_actived.png');
    }
    &.disabled {
        pointer-events: none;
        opacity: .6;
    }
}


.btn-action {
    width: 120px;
    height: 40px;
    font-size: 20px;
    text-align: center;
    text-decoration: none;
    line-height: 40px;
    color: #ededed;
    margin-left: 6px;
    cursor: pointer;
}
.btn-normal {
    background: url('./assets/btn-normal.png') center center no-repeat;

    &:hover {
        background-image: url('./assets/btn-normal_hover.png');
    }
    &:active {
        background-image: url('./assets/btn-normal_actived.png');
    }
}
.btn-danger {
    background: url('./assets/btn-danger.png') center center no-repeat;

    &:hover {
        background-image: url('./assets/btn-danger_hover.png');
    }
    &:active {
        background-image: url('./assets/btn-danger_actived.png');
    }
}
.btn-done {
    background: url('./assets/btn-done.png') center center no-repeat;

    &:hover {
        background-image: url('./assets/btn-done_hover.png');
    }
    &:active {
        background-image: url('./assets/btn-done_actived.png');
    }
}

.comment-form {
    align-items: center;

    .field {
        flex: 1;
        display: flex;
        line-height: 32px;
        margin-bottom: 15px;
        position: relative;
    }
    .label {
        font-size: 20px;
        color: #5D3F39;
        margin-right: 8px;
    }
    .title {
        width: 670px;
        height: 32px;
        padding: 0 60px 0 10px;
        font-size: 16px;
        color: #5d3f39;
        box-sizing: border-box;
        background: transparent;
        border-image: url(./assets/input.png) 9 fill / 9px round;

        &:focus {
            outline: 1px solid rgba(0, 0, 0, 0.1);
        }
        &.error {
            background-color: rgba(255, 0, 0, .1);
        }
    }
    .content {
        resize: none;
        width: 670px;
        height: 188px;
        padding: 10px 10px 30px;
        line-height: 24px;
        font-size: 16px;
        color: #5d3f39;
        box-sizing: border-box;
        background: transparent;
        border-image: url(./assets/input.png) 9 fill / 9px round;

        &:focus {
            outline: 1px solid rgba(0, 0, 0, 0.1);
        }
        &.error {
            background-color: rgba(255, 0, 0, .1);
        }

        & + .count {
            bottom: -30px;
        }
    }
    .count {
        position: absolute;
        right: 10px;
        bottom: 0;
        color: #907D79;

        &.error {
            color: red;
        }
    }
}

.page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}
.page-wrapper {
    display: flex;
    flex-direction: column;
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    position: relative;
}