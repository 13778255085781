.pager {
    display: flex;
    align-items: center;
    justify-content: center;
}
.pager-anchor {
    text-align: center;
    height: 36px;
    padding: 0 10px;
    margin-right: 8px;
    background: rgba(0,0,0,0.4);
    font-size: 20px;
    line-height: 36px;
    color: #EDEDED;
    cursor: pointer;

    &.disabled {
        box-shadow: none;
        cursor: not-allowed;
        border: 1px solid #ccc;
        color: #999;
    }

    &.current {
        background-color: rgba(144,125,121,0.4);;
        cursor: default;
    }
}