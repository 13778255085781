.message-contaner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 101;
    pointer-events: none;
}
.message-wrapper {
    width: 436px;
    height: 123px;
    background: url(../../assets/toast_bg.png) center no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px 0 0;
    box-sizing: border-box;
    transform: scale(0);
    opacity: 0;
    transition: all .3s ease-in-out;

    &.show {
        transform: scale(1);
        opacity: 1;
    }
}
.message-content {
    color: #5D3F39;
    font-size: 16px;
    line-height: 24px;
    width: 300px;
    text-align: center;
}