.page-streamer {
    background: #000 url(../../assets/streamer_bg.jpg) center / 2560px 1440px no-repeat;
}
.page-streamer-header {
    position: relative;
    z-index: 1;

    .page-title {
        position: absolute;
        left: 382px;
        top: 28px;
        width: 122px;
        height: 34px;
        background: url(../../assets/streamer_title.png) center no-repeat;
    }

    .btn-back {
        position: absolute;
        left: 245px;
        top: 11px;
        cursor: pointer;
    }
}
.page-streamer-content {
    flex: 1;
    overflow: hidden;
    display: flex;
    position: absolute;
    top: 190px;
    left: 390px;
    right: 390px;
    bottom: 50px;
}
