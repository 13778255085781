.streamer-list-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
}
.streamer-list-container {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
}
.streamer-list-header {
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px;
}
.streamer-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 16px;
    align-items: stretch;
    justify-content: flex-start;
}
.streamer-list-footer {
    display: flex;
    padding: 20px 20px 0;
    justify-content: flex-end;
}
.streamer-searchbar {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .search-key {
        width: 360px;
        height: 34px;
        background: transparent;
        border-image: url(../../assets/input.png) 9 fill / 9px round;
        padding: 0 30px 0 5px;
        box-sizing: border-box;
        font-size: 14px;
        color: #5D3F39;

        &:focus {
            outline: 0 none;
        }
    }

    .search-btn {
        width: 42px;
        height: 42px;
        margin-left: 8px;
        cursor: pointer;
        background: url('../../assets/btn-search.png') center center no-repeat;

        &:hover {
            background-image: url('../../assets/btn-search_hover.png');
        }
        &:active {
            background-image: url('../../assets/btn-search_actived.png');
        }
        &.disabled {
            pointer-events: none;
            opacity: .6;
        }
    }

    .clear-btn {
        position: absolute;
        font-size: 30px;
        left: 335px;
        top: 2px;
        line-height: 34px;
        color: #5D3F39;
        cursor: pointer;
    }
}
.streamer-toolbar {
    display: flex;
    flex-direction: column;
}
.streamer-card {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 16px 16px 0;
    width: 356px;
    height: 130px;
    background: url(../../assets/streamer-card_bg.png) center no-repeat;

    &:nth-child(3n) {
        margin-right: 0;
    }

    .card-header {
        display: flex;
        align-items: center;
    }
    .card-platfrom {
        display: flex;
        align-items: center;
        margin: 5px 0 0 20px;
    }
    .card-platfrom-icon {
        width: 28px;
        height: 28px;
        margin-right: 5px;
        background-size: 100%;

        &.icon-douyin {
            background-image: url(../../assets/icon-douyin.png);
        }
        &.icon-bilibili {
            background-image: url(../../assets/icon-bilibili.png);
        }
        &.icon-douyu {
            background-image: url(../../assets/icon-douyu.png);
        }
        &.icon-kuaishou {
            background-image: url(../../assets/icon-kuaishou.png);
        }
        &.icon-huya {
            background-image: url(../../assets/icon-huya.png);
        }
    }
    .card-platfrom-name {
        color: #907D79;
        font-size: 16px;
    }
    .card-content {
        height: 45px;
        color: #333;
        line-height: 1.5;
        padding: 5px 0 0 25px;
    }
    .card-name {
        font-size: 24px;
        color: #5D3F39;
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .card-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .btn-action {
            margin-right: 8px;
        }
    }
    .card-info {
        font-size: 16px;
        color: #907D79;
        margin-left: 25px;
    }
    .card-toolbar {
        display: flex;
        align-items: center;
    }
    .card-forward-info {
        font-size: 16px;
        color: #907D79;
        margin-left: 25px;
    }
    .card-forward-tag {
        width: 140px;
        height: 56px;
        font-size: 20px;
        text-align: center;
        text-decoration: none;
        line-height: 56px;
        color: #36AF81;;
        margin-left: 6px;
        background-image: url('../../assets/btn-forward_actived.png');
    }
}
.comment-view-content {
    display: flex;
    flex: 1;
    overflow: hidden;
    background-color: #f1f3f5;
}


.comment-modal {
    display: flex;
    flex-direction: column;
    width: 1446px;
    height: 1026px;
    background: url(../../assets/forward-modal-bg.png) center no-repeat;
}
.comment-modal-header {
    position: relative;
    z-index: 1;
    height: 140px;

    .page-title {
        position: absolute;
        left: 209px;
        top: 58px;
        width: 61px;
        height: 34px;
        background: url(../../assets/forward_title.png) center no-repeat;
    }

    .btn-close {
        position: absolute;
        right: 100px;
        top: 44px;
        cursor: pointer;
    }
}
.comment-modal-body {
    display: flex;
    flex: 1 1;
    overflow: hidden;
    margin: 0 108px 60px 195px;
}