.page-comment {
    background: #000 url(../../assets/comment_bg.jpg) center / 2560px 1440px no-repeat;
}
.page-comment-header {
    position: relative;
    z-index: 1;

    .page-title {
        position: absolute;
        left: 318px;
        top: 28px;
        width: 150px;
        height: 34px;
        background: url(../../assets/comment_title.png) center no-repeat;
    }

    .btn-exit {
        position: absolute;
        right: 250px;
        top: 41px;
        cursor: pointer;
    }

    .btn-publish {
        position: absolute;
        right: 433px;
        top: 34px;
        width: 261px;
        height: 123px;
        background: url(../../assets/btn-my..png) center no-repeat;

        &:hover {
            background-image: url(../../assets/btn-my_hover.png);
        }
        &:active {
            background-image: url(../../assets/btn-my_actived.png);
        }
    }

    .btn-streamer {
        position: absolute;
        right: 503px;
        top: 171px;
        transform: scale(1.3);
    }
}
.page-comment-content {
    flex: 1;
    overflow: hidden;
    display: flex;
    position: absolute;
    top: 190px;
    left: 550px;
    right: 550px;
    bottom: 50px;
}
