.modal-mask {
    position: fixed;
    left: -320px;
    right: -320px;
    top: -180px;
    bottom: -180px;
    background: rgba(0, 0, 0, .6);
    z-index: 99;
    display: none;
    align-items: center;
    justify-content: center;

    &.visible {
        display: flex;
    }
}
.modal-container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.modal-header {
    display: flex;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
}
.modal-title {
    font-size: 20px;
    color: #333;
}
.modal-content {
    display: flex;
    font-size: 18px;
    color: #333;
    flex: 1;
    overflow: hidden;
}
.modal-message {
    padding: 30px 30px;
}
.modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
    border-top: 1px solid #ccc;
}